import React from 'react';

const Profile = (props) => {
    return (
        <div className="mt-8 flex items-center justify-center">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg w-1/2 py-4">
                <div className="px-4 py-5 border-b border-gray-200 sm:px-6 flex items-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Profile Information
                    </h3>
                    <div className="relative m-6 block h-12 w-12 rounded-full overflow-hidden shadow">
                        <img
                            src={props.profile.avatar}
                            className="h-full w-full object-cover" />
                    </div>
                    <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                    </p>
                </div>
                <div>
                    <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                Full name
                            </dt>
                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                               {props.profile.name}
                            </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm leading-5 font-medium text-gray-500">
                                Email address
                            </dt>
                            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                {props.profile.email}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    )
};
export default Profile;